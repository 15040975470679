var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-device-list-component table-component" },
    [
      _c(
        "jtl-button",
        { attrs: { type: "primary", "click-prop": _vm.addDevice } },
        [_vm._v("添加设备")]
      ),
      _vm._v("  "),
      _c(
        "jtl-button",
        { attrs: { type: "primary", "click-prop": _vm.asyncDevice } },
        [_vm._v("同步设备")]
      ),
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: _vm.Pagination,
          scroll: _vm.tableScroll,
          "row-key": _vm.TableRowKey,
          size: _vm.TableSize,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            onSelect: _vm.onSelect,
          },
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.unlinkClick(record)
                      },
                    },
                  },
                  [_vm._v("解除关联")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("EdgeAddDeviceDialog", {
        ref: "edgeAddDeviceDialog",
        on: { dialogOK: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }