























import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { DeviceEntityModel } from '@common-src/entity-model/device-entity';
import EdgeService from '@/service/edge';
import { EdgeEntityModel } from '@/entity-model/edge-entity';
import EdgeAddDeviceDialog from './add-device.dialog.vue';

@Component({ components: { EdgeAddDeviceDialog } })
export default class EdgeDeviceListComponent extends TableComponent<DeviceEntityModel, null> {
    @Prop()
    edgeId: string;
    created() {
        this.pageSize = 500;
        this.initTable({
            listFunc: EdgeService.getEdgeDeviceList,
            queryModel: { params: { edgeId: this.edgeId } } as any,
            tableColumns: EdgeEntityModel.getDeviceTableColumns()
        });
        this.getList();
    }

    addDevice() {
        (this.$refs.edgeAddDeviceDialog as EdgeAddDeviceDialog).dialogOpen(this.edgeId);
    }

    asyncDevice() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择需要同步的内置网关');
            return;
        }
        return EdgeService.linkDevice(this.edgeId, this.getSelectedRowIds()).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            // this.getList();
        });
    }

    unlinkClick(model: DeviceEntityModel) {
        EdgeService.unlinkDevice(this.edgeId, [model.id]).then(res => {
            this.removeModel(model);
            this.showMessageSuccess('解除关联成功');
        });
    }
}
