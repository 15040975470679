












































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { EdgeEntityModel, EdgeQueryModel } from '@/entity-model/edge-entity';
import EdgeService from '@/service/edge';
import EdgeDetailDrawer from './edge-detail.drawer.vue';

@Component({
    components: {
        'edge-detail-drawer': EdgeDetailDrawer
    }
})
export default class EdgeListComponent extends TableDialogFormComponent<EdgeEntityModel, EdgeQueryModel> {
    EdgeEntityModel = EdgeEntityModel;
    created() {
        this.initTable({
            service: new EdgeService(),
            queryModel: new EdgeQueryModel(),
            tableColumns: EdgeEntityModel.getTableColumns()
        });
        this.getList();
    }

    detailClick(model: EdgeEntityModel) {
        (this.$refs.edgeDetailDrawer as EdgeDetailDrawer).drawerShow(model);
    }
}

