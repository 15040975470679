var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-device-type-list-component table-component" },
    [
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: _vm.Pagination,
          scroll: _vm.tableScroll,
          "row-key": _vm.TableRowKey,
          size: _vm.TableSize,
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.detailClick(record)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("device-type-detail-drawer", { ref: "deviceTypeDetailDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }