























import { Component, Prop } from 'vue-property-decorator';
import DeviceTypeDetailDrawer from '@common-src/pages3/dashboard/device-type/device-type-detail-drawer.vue';
import TableComponent from '@common-src/mixins/table-component';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import EdgeService from '@/service/edge';
import { EdgeEntityModel } from '@/entity-model/edge-entity';

@Component({
    components: {
        'device-type-detail-drawer': DeviceTypeDetailDrawer
    }
})
export default class EdgeDeviceTypeListComponent extends TableComponent<DeviceTypeEntityModel, null> {
    @Prop()
    edgeId: string;
    created() {
        this.initTable({
            listFunc: EdgeService.getEdgeDeviceTypeList,
            queryModel: { params: { edgeId: this.edgeId } } as any,
            tableColumns: EdgeEntityModel.getDeviceTypeTableColumns()
        });
        this.getList();
    }

    detailClick(model: DeviceTypeEntityModel) {
        (this.$refs.deviceTypeDetailDrawer as DeviceTypeDetailDrawer).drawerShow(model);
    }

    syncAllProduct() {
        return EdgeService.edgeDeviceTypeSync(this.edgeId).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            this.getList();
        });
    }

    syncOneProduct(model: EdgeEntityModel) {
        this.startFullScreenLoading('正在同步...');
        return EdgeService.edgeDeviceTypeForceSync(this.edgeId, model.id).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            // this.getList();
        }).finally(() => { this.stopFullScreenLoading(); });
    }
}
