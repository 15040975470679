




































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetGroupService from '@common-src/service/asset-group';
import AssetService from '@common-src/service/asset';
import { FormControlType } from '@common-src/model/form-control';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import FormComponent from '@common-src/mixins/form-component';
import EdgeService from '@/service/edge';

@Component
export default class EdgeAddDeviceDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;

    created() {
        this.debounceTransformGetList = _.debounce(this.transformGetList, 500);
        this.pageSize = null;
        this.isLoadMore = true;
        this.isAutoQuery = false;
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.initTable({ listFunc: EdgeService.getEdgeDeviceList, queryModel: deviceQueryModel });
    }

    dialogOpen(modelId: string) {
        this.modelId = modelId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        (this.queryModel as DeviceQueryModel).groupIds = null;
        (this.queryModel as DeviceQueryModel).name = null;
        this.getList();
    }

    dialogOK() {
        if (!this.rightDataList || this.rightDataList.length === 0) {
            this.showMessageWarning('请选择添加的内置网关');
            return;
        }
        return EdgeService.linkDevice(this.modelId, _.map(this.rightDataList, item => item.id)).then((res) => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            this.$emit('dialogOK');
            this.dialogClose();
        });
    }
}
